import { Heading, Text, Box, UnorderedList, ListItem, OrderedList, Container, Grid, GridItem, AspectRatio, WrapItem, SimpleGrid, List } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDuration } from 'date-fns'

function Recipe() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [recipe, setRecipe] = useState([]);

    const params = useParams();

    useEffect(() => {
        fetch("/api/recipes/" + params.recipeId)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setRecipe(result);
              //setRecipe(JSON.stringify(result));
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])
    
    if(!isLoaded) {
        return (
            <Text>Loading recipe...</Text>
        )
    } else {
      console.log(recipe.ingredients)
        return (
            <>
              <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={4}>
                <GridItem colSpan={2}>
                  <AspectRatio ratio={1}>
                    <Image src={recipe.image}></Image>
                  </AspectRatio>
                </GridItem>
                <GridItem colSpan={3}>
                  <Heading mb={3}>{recipe.name}</Heading>
                  <Box>
                    <SimpleGrid columns={3}>
                      {recipe.prepTime ? <Box>Prep Time: {formatDuration(recipe.prepTime)}</Box> : ''}
                      {recipe.cookTime ? <Box>Cook Time: {formatDuration(recipe.cookTime)}</Box> : ''}
                      {recipe.performTime ? <Box>Perform Time: {formatDuration(recipe.performTime)}</Box> : ''}

                      <Box>Source:</Box>
                    </SimpleGrid>
                    <Heading mb={2} size="md">Description</Heading>
                    <Text>{recipe.description}</Text>
                  </Box>
                  <Box>
                    Tags here!
                  </Box>
                </GridItem>
              </Grid>
              <Grid templateColumns='repeat(12, 1fr)' mt={8}>
                <GridItem colSpan={4}>
                  <Heading size="md" mb={4}>Ingredients</Heading>
                  <UnorderedList>
                    {recipe.ingredients ? (
                      recipe.ingredients.map(ingredient =>
                        <ListItem>{ingredient}</ListItem>
                      )
                    ) : (
                      <ListItem>We couldn't load ingredients</ListItem>
                    )}
                  </UnorderedList>
                </GridItem>
                <GridItem colSpan={8}>
                  <Heading size="md" mb={4} >Instructions</Heading>
                  <OrderedList>
                    {recipe.instructions ? (
                      recipe.instructions.map(ingredient =>
                        <ListItem>{typeof ingredient === 'object' ? ingredient.text : ingredient}</ListItem>
                      )
                      ) : (
                      <ListItem>We couldn't load instructions</ListItem>
                    )}
                  </OrderedList>
                </GridItem>
              </Grid>
            </>
        );
    }  
}

export default Recipe;