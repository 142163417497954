import { useAuth0 } from "@auth0/auth0-react";
import { Container, Heading, Text } from "@chakra-ui/layout";
import { Outlet } from "react-router";

export default function Profile() {
    const {user, isAuthenticated } = useAuth0();

    if (isAuthenticated) {
        return (
            <main>
              <Container maxW="container.xl" my="6">
                <Heading>Profile</Heading>
                <Heading>{user.name}</Heading>
                <Text>{JSON.stringify(user)}</Text>
              </Container>
            </main>
          );
    } else {
        return (
            <main>
                <Text>No user</Text>
            </main>
        )
    }

    
    
  }