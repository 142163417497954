import { render } from "react-dom";
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from '@chakra-ui/react'
import Layout from "./Layout";
import Home from "./routes/home";
import Recipes from "./routes/recipes";
import Recipe from './routes/recipes/recipe';
import './index.scss';
import Profile from "./routes/profile";

const rootElement = document.getElementById("root");
render(
  <Auth0Provider
    domain="y3d.eu.auth0.com"
    clientId="MxEErSK7qAyQb9bPw8budx4XSkiumhWx"
    redirectUri={window.location.origin}>
  <ChakraProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="recipes" element={<Recipes />}>
            <Route index element={
              <main>
                This is the main content
              </main>
            }
            />
            <Route path=":recipeId" element={<Recipe />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
  </Auth0Provider>,
  rootElement
);