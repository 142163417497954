import { Container } from "@chakra-ui/layout";
import { Outlet } from "react-router";
import Navbar from "./components/navbar";



function Layout() {
    return (
        <div className="website">
            <Navbar />
                <Outlet />
        </div>
      );
}

export default Layout;