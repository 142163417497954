import { Image } from "@chakra-ui/image";
import { AspectRatio, Box, Heading, Link, Text, Wrap, WrapItem } from "@chakra-ui/layout";
import { Link as ReactLink } from "react-router-dom";

function Card({recipe}) {

    return (
        <WrapItem width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
          <Box w="100%" p={4}>
            <Box borderRadius="lg" overflow="hidden">
              <AspectRatio ratio={1}>
                <Link textDecoration="none" _hover={{ textDecoration: 'none' }} as={ReactLink} to={'recipes/' + recipe['_id']}>
                  <Image
                    transform="scale(1.0)"
                    src={recipe.image}
                    alt="some text"
                    objectFit="contain"
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: 'scale(1.05)',
                    }}
                  />
                </Link>
              </AspectRatio>
            </Box>
            <Heading fontSize="xl" marginTop="2">
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                { recipe.name }
              </Link>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2">
              {recipe.description}
            </Text>
          </Box>
        </WrapItem>
    )
}

export default Card;