import { Box, Center, Heading, SimpleGrid, Link, Wrap, Container } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Card from "../components/recipes/card";

export default function Home() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [recipes, setRecipes] = useState([]);

    useEffect(() => {
        fetch("/api/recipes")
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              console.log(result);
              setRecipes(result);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])


    return (
        <Fragment>
          <Container maxW='container.xl'>
            <Heading>Welcome to your recipe book</Heading>
            {!isLoaded
              ? <Box> We are loading your recipes...</Box>
              : <Wrap spacing='20px'>
                {recipes.map(recipe => (
                  <Card recipe={recipe} />
                ))}
              </Wrap>
            }
    
          </Container>
        </Fragment>
    );
}