import { Container } from "@chakra-ui/layout";
import { Outlet } from "react-router";

export default function Recipes() {
    return (
      <main>
        <Container maxW="container.xl" my="6">
          <Outlet></Outlet>
        </Container>
      </main>
    );
  }