import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHatChef } from "@fortawesome/pro-regular-svg-icons";
import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useAuth0 } from "@auth0/auth0-react";

export default function Navbar() {
    const routes = [
        {
            name: 'Home',
            to: '/',
        },
        {
            name: 'Recipes',
            to: '/recipes',
        }
    ]
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user, isAuthenticated, isUserLoading, loginWithRedirect } = useAuth0();
    return (
        <>
<Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
  <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
    <IconButton
      size={'md'}
      icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
      aria-label={'Open Menu'}
      display={{ md: 'none' }}
      onClick={isOpen ? onClose : onOpen}
    />
    <HStack spacing={8} alignItems={'center'}>
      <Box><FontAwesomeIcon icon={ faHatChef } /> The Recipe Book</Box>
      <HStack
        as={'nav'}
        spacing={4}
        display={{ base: 'none', md: 'flex' }}>
        {routes.map((route) => (
          <Link
          px={2}
          py={1}
          rounded={'md'}
          _hover={{
            textDecoration: 'none',
            }}
          href={route.to}>
          {route.name}
        </Link>
        ))}
      </HStack>
    </HStack>
    <Flex alignItems={'center'}>
      {isUserLoading ? "User still loading" : "Not Loading anymore!"}
      {isAuthenticated
      ? <Menu>
        <MenuButton
          as={Button}
          rounded={'full'}
          variant={'link'}
          cursor={'pointer'}
          minW={0}>
          <Avatar
            size={'sm'}
            src={
              'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
            }
          />
        </MenuButton>
        <MenuList>
          <MenuItem>Link 1</MenuItem>
          <MenuItem>Link 2</MenuItem>
          <MenuDivider />
          <MenuItem>Link 3</MenuItem>
        </MenuList>
      </Menu>
      : <Button onClick={() => loginWithRedirect()}>Log in</Button>
      }
      
    </Flex>
  </Flex>

  {isOpen ? (
    <Box pb={4} display={{ md: 'none' }}>
      <Stack as={'nav'} spacing={4}>
        {routes.map((route) => (
          <Link
          px={2}
          py={1}
          rounded={'md'}
          _hover={{
            textDecoration: 'none',
          }}
          href={route.to}>
          {route.name}
        </Link>
        ))}
      </Stack>
    </Box>
  ) : null}
</Box>
</>
);
}